<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid get-asst-bgimg"
							style="text-align:left;">
							<div class=" full_section_inner clearfix">
								<div class="wpb_column vc_column_container vc_col-sm-6 vc_col-has-fill">
									<div class="vc_column-inner get-asst-whitebg">
										<div class="wpb_wrapper">
											<div class="wpb_text_column wpb_content_element ">
												<div class="wpb_wrapper">
													<h1>Have you taken your<br />
														self-assessment?</h1>
													<p>Whether you are experiencing discomfort or just curious about
														ergonomics, this is the place to start! Taking the self
														assessment will improve your productivity and help you get rid
														of (or avoid) pesky aches and pains. Give us 10 minutes of your
														time, we will give you a better you.</p>
													<p>&nbsp;</p>
													<p>
														<router-link :to="{ name: 'self-assessment-a45' }" class="button red">
															Start your self-assessment 
														</router-link>
														<!-- <router-link :to="{ name: 'self_assessment' }" class="btn btn_primary mb_120">
															Start Your Self-Assessment
														</router-link> -->
													</p>
													<p>&nbsp;</p>
													<p>&nbsp;</p>
													<div v-if="company_data.schedule_request_status">
													<h3>Already completed a self-assessment?</h3>
													<p v-html="company_data.schedule_request"></p>
													<p>&nbsp;</p>
													<p>
														<router-link :to="{ name: 'schedule-evaluation-a45' }" class="button red">
															Schedule an evaluation
														</router-link>
													</p>
													</div>
												</div>
											</div>
											<div class="vc_empty_space" style="height: 50px"><span
													class="vc_empty_space_inner">
													<span class="empty_space_image"></span>
												</span></div>

										</div>
									</div>
								</div>
								<div class="wpb_column vc_column_container vc_col-sm-6">
									<div class="vc_column-inner">
										<div class="wpb_wrapper"></div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
			

			</div>
		</div>

	</div>
</template>
<script>
	//import carousel from "vue-owl-carousel"
	export default {
		name: 'get-an-assessment-a45',
		data() {
    return {
      company_data: {
         schedule_request_status:true,
         schedule_request:`Do you need a bit more help after taking your self-assessment?
                  We’ve got you covered. Schedule a one-on-one ergo evaluation
                  with one of our leading ergonomists.`
      },
    };
  },
  mounted() {
	let data = this.$store.getters["auth/authUser"];
	let company_data = data.client;
    // let company_data = JSON.parse(localStorage.getItem("companyInformation"));
    if (company_data == null) {
      company_data = [];
    }
    if (company_data.length == 0 || company_data.length === "undefined") {
      console.log("no company values home");      
    } else {
      this.company_data.schedule_request_status = company_data.schedule_request_status;
      this.company_data.schedule_request = company_data.schedule_request;
    }
  },
		methods: {
			hideModal() {
				// this.$emit('hide');
				document.getElementById("closed").src += "";
			},
		}
	}
</script>